<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <b-form
          
          
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
           
  
        

              <!-- Spacer -->
              <hr class="invoice-spacing">
<b-row>
     
        <b-col xl="3">
          <b-button variant="purple" class="shadow mb-3" >
           
        <vue-excel-xlsx
            ref="childComponent"
            :data="ItemList"
            :columns="columns"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'sheetname'"
            class="button-excel"
          >
            <span> Export To Excel</span>
          </vue-excel-xlsx>
          
        </b-button>
        </b-col>
  </b-row>
              <!-- Invoice Client & Payment Details -->
              
              <!-- Items Section -->
          

              <!-- Invoice Description: Total -->

              <!-- Spacer -->
                  <b-table
        ref="refFamiliesListTable"
        :items="ItemList"
        responsive
        :fields="tableColumns"
        primary-key="id"
    
      >
        <!-- Column: editfield -->

     
      </b-table>
            <!-- Note -->
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>
    <b-row />

    <!-- <invoice-sidebar-send-invoice />
        <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,

  BForm,
  BFormGroup,
  BFormInput,

  BFormInvalidFeedback,
BTable,
  VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import financeStoreModule from './financeStoreModule.js'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    required,
    ValidationProvider,
    ValidationObserver,
    financeStoreModule,
    BFormInvalidFeedback,
    BCard,
    BCardBody,
    BButton,

    BForm,
    BFormGroup,
    BFormInput,

    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    vSelect,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'invoiceData.items': function () {
      this.initTrHeight()
    },
  },
  data(){
    return{
      columns: [
       
        {
          label: "المادة",
          field: "item",
        },
        {
          label: "الواحدة",
          field: "unit",
        },
        {
          label: "الكمية",
          field: "quantity",
        },
         {
          label:" رقم أمر الشراء",
          field: "po_number",
        },
        
         {
          label:"   الحالة",
         field: "status",
        },
         {
          label:"   القسم",
         field : "department_id",
        },
         {
          label:"   المستلم",
         field : "recived_by_user",
        },
        {
          label:"   اسم المورد",
         field: "vendor",
        },
         {
          label:"   رقم الفاتورة",
         field: "pill_number",
        },
        {
          label:"    تاريخ تسليم",
         field: "recive_date",
        },
        {
          label: "الكمية المتبقية",
          field: "remaining_quantity",
        },

      ],
        filename: "المواد منتهية الصلاحية",
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
  async download() {
      return new Promise((resolve, reject) => {
        
 this.$refs.childComponent.exportExcel();
      });
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
  },
  setup() {
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })

    const Form = ref({
      type_id: '',
      from_party_id: null,
      to_party_id: null,
      user_material_order_id: 1,
      request_by_user: 1,
      logistic_officer_signature: null,
      project_manager_signature: null,
      donor_id: 1,
      warehouse_id: 1,
      out_put_id: 1,
      out_come_id: 1,
      item_id: null,
      sub_item_id: null,
      cost_center: null,
      department_id: '',
      ppa_number: null,
      notes: '',
      in_transactions: [
      
      ],
      out_transactions: [
      
      ],
    })
    const invoiceData = ref(null)
    const accountList = ref([])
    const SubAccountList = ref([])

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const itemsOptions = ref([
      {
        item_id: '',
        quantity: null,

        unit_id: '',
        price: null,
        currency_id: '',
      },

    ])
    const addNewItemInItemForm = () => {
      Form.value.details.push(
        {
          item_id: '',
          quantity: null,

          unit_id: '',
          price: null,
          currency_id: '',

        },
      )
    }
    const removeItem = index => {
      Form.value.details.splice(index, 1)
    }
    const ItemData = ref([])
    const ItemList = ref([])
  
    const ubitsOption = ref([])
  
    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].description = description
    }
    const donorOption = ref([])
    const WarehouseOption = ref([])
   
    const outComeOption = ref([])
    const outPutOption = ref([])

    const getDetails = () => {
      SubAccountList.value = []
      store.dispatch('app-finance/getItemExpirationDate').then(response => {
     
        ItemList.value=response

    })}
    getDetails()
   
  
    const tableColumns = [

    {
      key: 'main_warehouse_id',
      label: '  المستودع الرئيسي',

      sortable: true,
    },
    {
      key: 'sub_warehouse_id',
      label: ' المستودع الفرعي ',

      sortable: true,
    },
      {
      key: 'item',
      label: ' المادة  ',

      sortable: true,
    },  {
      key: 'item_code',
      label: ' الكود  ',

      sortable: true,
    },
    {
      key: 'unit',
      label: ' الواحدة ',

      sortable: true,
    },
    {
      key: 'end_date',
      label: '  تاريخ الصلاحية ',

      sortable: true,
    },
    {
      key: 'recived_by_user',
      label: ' المستلم ',

      sortable: true,
    },
 {
      key: 'remaining_quantity',
      label: "الكمية المتبقية",

      sortable: true,
    },
     {
      key: 'recived_by_user',
      label: ' المستلم ',

      sortable: true,
    },
      {
      key: 'department_id',
      label: ' القسم ',

      sortable: true,
    },
      {
      key: 'pill_number',
      label: ' رقم الفاتورة ',

      sortable: true,
    },
      {
      key: 'po_number',
      label: ' رقم امر الشراء ',

      sortable: true,
    },
       {
      key: 'notes',
      label: ' الملاحظات ',

      sortable: true,
    },
  
    // { key: 'status', sortable: true },
  
  ]
    return {
 
         tableColumns,
      Form,

      addNewItemInItemForm,
      removeItem,
      ItemData,
      ItemList,
      ubitsOption,
      accountList,
      getDetails,
      SubAccountList,
      WarehouseOption,
      donorOption,
      outComeOption,
      outPutOption,
    
      invoiceData,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
 
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
button.btn.btn-outline-primary {
    margin: 1.375rem !important;
}
.d-lg-none {
    display: block !important;
}
.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
button.btn.me-20.btn-outline-danger {
  margin-right: 17px;
}
</style>
